import React, { Component } from 'react';
import { Grid, Tabs, Tab, TextField } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../reducers/index';
import { getNotificationsAction } from '../actions/notifications';
import { PushNotification } from '../reducers/notifications';
import CreateCampaignButton from '../components/Dashboard/Buttons/CreateCampaign';
import { createCampaign } from '../actions/createCampaign';
import { deleteCampaignThunkAction } from '../actions/deleteCampaign';
import { extractFormData } from '../reducers/campaignBuilder';
import { notMissing, isMissing } from '@yieldify/gendry-dragonglass';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CampaignCard from '../components/Dashboard/CampaignCard';

const DUPLICATE_CAMPAIGN_NAME_PREFIX = 'Copy of ';
interface DashboardOwnProps {
  getAllNotifications: Function;
  notificationsPending: boolean;
  notifications: PushNotification[];
  notificationsError: string | null;
  addCampaign: Function;
  duplicateCampaign: Function;
  deleteCampaign: Function;
}

export type DashboardProps = RouteComponentProps & DashboardOwnProps;

interface DashboardState {
  viewSelected: string;
  clientInputValue: string;
  campaignInputValue: string;
  sortingInDescendingOrder: boolean;
}

export class Dashboard extends Component<DashboardProps, DashboardState> {
  public constructor(props: DashboardProps) {
    super(props);
    this.state = {
      viewSelected: 'all',
      clientInputValue: '',
      campaignInputValue: '',
      sortingInDescendingOrder: true,
    };
  }

  public componentDidMount(): void {
    this.props.getAllNotifications();
  }

  public render(): JSX.Element {
    const {
      notifications,
      notificationsPending,
      notificationsError,
      addCampaign,
      duplicateCampaign,
      deleteCampaign,
    } = this.props;
    const {
      clientInputValue,
      campaignInputValue,
      sortingInDescendingOrder,
      viewSelected,
    } = this.state;

    const notificationsFiltered = this.filterNotifications(
      clientInputValue,
      campaignInputValue,
      viewSelected,
      sortingInDescendingOrder,
      notifications,
    );

    return (
      <div className="dashboard">
        <div className="title">
          <h1>Dashboard</h1>
          <Tabs
            value={this.state.viewSelected}
            textColor="secondary"
            onChange={this.handleChangeView}
            aria-label="Notification Status"
            variant="fullWidth"
          >
            <Tab label="All" value="all" />
            <Tab label="Sent" value="sent" />
            <Tab label="Draft" value="draft" />
          </Tabs>
          <div className="container">
            <TextField
              value={this.state.clientInputValue}
              id="filled-basic"
              label="Filter By Client"
              onChange={(e) => this.handleChangeClientInput(e.target.value)}
            />
          </div>
          <div className="container">
            <TextField
              value={this.state.campaignInputValue}
              id="standard-basic"
              label="Filter By Campaign"
              onChange={(e) => this.handleChangeInput(e.target.value)}
            />
          </div>
          <div onClick={() => this.handleChronologicalOrder(sortingInDescendingOrder)}>
            {sortingInDescendingOrder ? (
              <ArrowDownwardIcon className="arrow" />
            ) : (
              <ArrowUpwardIcon className="arrow" />
            )}
          </div>
          <CreateCampaignButton className="create-campaign-fab" clickHandler={addCampaign} />
        </div>
        <div className="message">
          {notificationsPending && <h1>Getting notifications ....</h1>}
          {notificationsError && <h1>{notificationsError}</h1>}
        </div>
        <Grid>
          {notificationsFiltered.map((notification) => (
            <CampaignCard
              key={notification.id}
              notification={notification}
              duplicateCampaign={duplicateCampaign}
              deleteCampaign={deleteCampaign}
            />
          ))}
        </Grid>
      </div>
    );
  }

  // tslint:disable-next-line:variable-name
  private handleChangeView = (_event: React.ChangeEvent<{}>, viewSelected: string) => {
    this.setState({
      viewSelected,
    });
  };

  private handleChangeInput = (campaignInputValue: string) => {
    this.setState({
      campaignInputValue,
    });
  };

  private handleChangeClientInput = (clientInputValue: string) => {
    this.setState({
      clientInputValue,
    });
  };

  private handleChronologicalOrder(sortingInDescendingOrder: boolean): void {
    this.setState({
      sortingInDescendingOrder: !sortingInDescendingOrder,
    });
  }
  private filterNotifications(
    clientInputValue: string,
    campaignInputValue: string,
    view: string,
    sortingInDescendingOrder: boolean,
    notifications: PushNotification[],
  ): PushNotification[] {
    const filteredNotifications = notifications.filter(
      (notification) =>
        this.filterView(view, notification) &&
        this.filterByCampaignIdOrName(notification, campaignInputValue) &&
        this.filterByClientIdOrName(notification, clientInputValue),
    );
    return this.sortNotifications(filteredNotifications, sortingInDescendingOrder);
  }

  private filterView = (viewSelected: string, notification: PushNotification): boolean => {
    switch (viewSelected) {
      case 'draft':
        return isMissing(notification.sentAt);
      case 'sent':
        return notMissing(notification.sentAt);
      default:
        return true;
    }
  };

  private filterByCampaignIdOrName = (
    { id, name }: PushNotification,
    campaignInputValue: string,
  ): boolean => {
    return (
      id.toString().includes(campaignInputValue) ||
      name.toLowerCase().includes(campaignInputValue.toLowerCase())
    );
  };

  private filterByClientIdOrName = (
    { websiteId, clientName }: PushNotification,
    clientInputValue: string,
  ): boolean => {
    return (
      websiteId.toString().includes(clientInputValue) ||
      clientName.toLowerCase().includes(clientInputValue.toLowerCase())
    );
  };

  private sortNotifications(
    notifications: PushNotification[],
    sortingInDescendingOrder: boolean,
  ): PushNotification[] {
    return notifications.sort((a, b) => {
      return (
        new Date(sortingInDescendingOrder ? b.updatedAt : a.updatedAt).getTime() -
        new Date(sortingInDescendingOrder ? a.updatedAt : b.updatedAt).getTime()
      );
    });
  }
}

const mapStateToProps = ({ notifications, createdCampaign, deleteCampaign }: AppState) => {
  const { items, notificationsPending, notificationsError } = notifications;
  return {
    notifications: items,
    notificationsPending,
    notificationsError,
    campaignId: createdCampaign.campaignId,
    createCampaignPending: createdCampaign.loading,
    createCampaignError: createdCampaign.error,
    deleteCampaignPending: deleteCampaign.loading,
    deleteCampaignError: deleteCampaign.error,
  };
};

const mapDispatchToProps = (dispatch: Function, { history }: DashboardProps) => {
  return {
    getAllNotifications: () => dispatch(getNotificationsAction()),
    addCampaign: () => dispatch(createCampaign(history)),
    duplicateCampaign: (notification: PushNotification) => {
      const formData = extractFormData(notification);
      return dispatch(
        createCampaign(history, {
          ...formData,
          name: `${DUPLICATE_CAMPAIGN_NAME_PREFIX}${formData.name}`,
        }),
      );
    },
    deleteCampaign: (id: number, version: number) =>
      dispatch(deleteCampaignThunkAction(id, version)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

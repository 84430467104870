import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js/dist/typings/global';
import { isMissing } from '@yieldify/gendry-dragonglass';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { loginSuccessAction } from '../actions/users';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0Context {
  getIdTokenClaims: Auth0Client['getIdTokenClaims'];
  loginWithRedirect: Auth0Client['loginWithRedirect'];
  getTokenSilently: Auth0Client['getTokenSilently'];
  logout: Auth0Client['logout'];
}

export const Auth0Context = React.createContext<Auth0Context>({} as Auth0Context);
export const useAuth0 = () => useContext(Auth0Context);

export const AuthProvider: React.FC<Auth0ClientOptions & {
  dispatch: Dispatch;
  onRedirectCallback: Function;
}> = ({ children, dispatch, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      setIsLoading(true);
      const auth0ClientUpdated = await createAuth0Client(initOptions);
      setAuth0(auth0ClientUpdated);

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0ClientUpdated.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0ClientUpdated.isAuthenticated();

      if (isAuthenticated) {
        const user = await auth0ClientUpdated.getUser();
        const auth0Token = await auth0ClientUpdated.getTokenSilently();
        const userProfile = {
          token: auth0Token,
          user,
        };
        dispatch(loginSuccessAction(userProfile));
      }
      setIsLoading(false);
    };
    // tslint:disable-next-line:no-floating-promises
    initAuth0();
  }, []);
  if (isMissing(auth0Client) || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Auth0Context.Provider
      value={{
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default connect()(AuthProvider);

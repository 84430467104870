import React from 'react';
import FirebaseIID from '../components/FirebaseIID';
import { getFirebaseMessagingSdk, isMessagingSupported } from '../util/firebase';
import { config } from '../config';
import { IID, newIIDAction } from '../actions/web-push-client';
import { connect } from 'react-redux';
import { Action } from 'redux';

interface DispatchProps {
  dispatchIID: (iid: IID | null) => void;
}
interface OwnProps {
  className?: string;
}

export type Props = DispatchProps & OwnProps;

const IIDWrapper = ({ dispatchIID, className }: Props) => (
  <div className={className}>
    {isMessagingSupported() ? (
      <FirebaseIID
        onNewToken={dispatchIID}
        firebaseMessagingSdk={getFirebaseMessagingSdk(
          config.messagingVapidPublicKey,
          config.messagingSenderId,
        )}
        serviceWorkerPath={config.serviceWorkerPath}
      />
    ) : (
      <span>Browser not supported</span>
    )}
  </div>
);

export const dispatchToProps = (dispatch: (action: Action) => void) => ({
  dispatchIID: (iid: IID | null) => dispatch(newIIDAction(iid)),
});

export default connect(null, dispatchToProps)(IIDWrapper);
